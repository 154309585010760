import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Layout from './layouts/Layout';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-datetime/css/react-datetime.css';
import 'react-image-lightbox/style.css';
import { service } from './network/service';
import './style/app.css'
import { DndProvider } from 'react-dnd';
import { getHTML5Backend } from './backend';


const App = () => {

  setInterval(function () {
    let currentUser = JSON.parse(localStorage.getItem('user'));
    if (currentUser == null) {
      localStorage.removeItem('user');
      window.location.href = "/";
      return false;
    } else {
      let email = localStorage.getItem('eml');
      let password = localStorage.getItem('psd');
      if (email != null) {
        service.login(email, password)
          .then((response) => {
            if (response.length === 0) {
              localStorage.removeItem('user');
              window.location.href = "/";
              return false;
            } else {
              if (response.status === 100) {
                const newToken = response.token;
                currentUser.token = newToken;
                localStorage.setItem('user', JSON.stringify(currentUser));
              }
            }
          })
      } else {
        localStorage.removeItem('user');
        window.location.href = "/";
        return false;
      }
    }
  }, 60 * 60 * 1000);
  return (
    <DndProvider backend={getHTML5Backend()}>
    <Router basename={process.env.PUBLIC_URL}>
      <Layout />
    </Router>
    </DndProvider>
  );
};

export default App;
